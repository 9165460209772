import { styled } from '@smooth-ui/core-sc';

const BgGrey = styled.section`
  align-items: center;
  background-color: rgba(148, 149, 152, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  width: 100%;
  min-height: 165px;
`;

export default BgGrey;
