import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LinearGauge } from 'canvas-gauges';

const numberFormat = new Intl.NumberFormat();

class ProgressTracker extends Component {
  constructor(props) {
    super(props);
    this.el = React.createRef();
  }

  componentDidMount() {
    const maxValue = () => {
      return this.props.pointsBalance <= 15000
        ? 20000
        : Math.ceil(this.props.pointsBalance / 5000) * 5000 +
            Math.ceil(this.props.pointsBalance / 5000) * 500;
    };
    const maxWidth = () => {
      return window.innerWidth < 1200 ? window.innerWidth - 50 : 1100;
    };
    const ticks = () => {
      let majorTicks = [];
      const mxValue = maxValue();
      const mnValue = 0;
      const multiplier = mxValue / 4;
      for (let i = mnValue; i <= mxValue; i += multiplier) {
        majorTicks = [...majorTicks, `${numberFormat.format(i)} points`];
      }
      return majorTicks;
    };

    const progressTracker = new LinearGauge({
      renderTo: 'progress-tracker',
      width: maxWidth(),
      height: 90,
      minValue: 0,
      maxValue: maxValue(),
      majorTicks: ticks(),
      minorTicks: 5,
      colorPlate: '#E4E4E5',
      colorBarStroke: '#18A950',
      borderShadowWidth: 0,
      borders: false,
      barBeginCircle: false,
      barWidth: 6,
      barLength: 80,
      tickSide: 'right',
      numberSide: 'right',
      needleSide: 'right',
      needleType: 'arrow',
      needleWidth: 5,
      colorNeedle: '#18A950',
      colorNeedleEnd: '#18A950',
      colorStrokeTicks: '#18A950',
      value: this.props.pointsBalance,
      colorBarProgress: '#18A950',
      fontNumbersSize: 24,
      colorTitle: '#18A950',
      fontTitleSize: 40,
      fontTitleWeight: 'bold',
      title: `${numberFormat.format(this.props.pointsBalance)} points`,
    });

    progressTracker.draw();
  }

  render() {
    return (
      <div>
        <canvas
          ref={canvas => {
            this.el = canvas;
          }}
          id="progress-tracker"
        />
      </div>
    );
  }
}

ProgressTracker.propTypes = {
  pointsBalance: PropTypes.number,
};

ProgressTracker.defaultProps = {
  pointsBalance: 0,
};

export default ProgressTracker;
